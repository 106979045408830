// SearchSwaggerList.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import UserFeedItem from '../container/UserFeedItem.js';
import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import withRouter from '../component/WithRouter.jsx';
import {
  InfiniteScroller,
  ScrollItem,
} from '../component/InfiniteScroller.jsx';
import Loading from '../component/Loading.jsx';
import { ButtonId } from '../resource/mixpanel.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import { CustomImpression } from '../resource/impressionConstants.js';

import {
  color,
  textColor,
  searchSwaggerFilterHeight,
} from '../style/variables.js';

import {
  searchType,
  searchSwaggerCategoryPath,
} from '../resource/searchConstants.js';
import MoreIconSource from '../../img/ic_next_white.svg';

const SCROLL_BAR_HEIGHT = 40;

export class SearchSwaggerList extends React.PureComponent {
  handleLoadMore = () => {
    const {
      sortingString,
      query,
      defaultCategory,
      nextPage,
      hasQuery,
      fetchUserSearch,
      fetchFeeds,
    } = this.props;
    if (hasQuery) {
      return fetchUserSearch({
        query,
        queryUrl: nextPage,
        sortings: sortingString.split('&').filter(i => i),
      });
    }
    return fetchFeeds({
      page: nextPage,
      type: defaultCategory,
    });
  };
  componentDidMount() {
    const { subscribeFeedChannel, defaultCategory } = this.props;
    subscribeFeedChannel({ feedName: defaultCategory });
  }
  componentDidUpdate(prevProps) {
    const { defaultCategory, subscribeFeedChannel, unsubscribeFeedChannel } =
      this.props;
    if (defaultCategory !== prevProps.defaultCategory) {
      unsubscribeFeedChannel({ feedName: prevProps.defaultCategory });
      subscribeFeedChannel({ feedName: defaultCategory });
    }
  }
  componentWillUnmount() {
    const { unsubscribeFeedChannel, defaultCategory } = this.props;
    unsubscribeFeedChannel({ feedName: defaultCategory });
  }
  render() {
    const {
      defaultCategory,
      query,
      nextPage,
      hasQuery,
      isOnSearchAll,
      location: { search },
      isNextPageFetching,
      resultIds,
      defaultIds,
      t,
    } = this.props;
    const list = hasQuery ? resultIds : defaultIds;
    if (list.length === 0) {
      return null;
    }
    return (
      <StyledSearchSwaggerList
        isOnSearchAll={isOnSearchAll}
        hasSwaggerFilter={!query && !isOnSearchAll}
      >
        {isOnSearchAll ? (
          <Header>
            {hasQuery ? (
              !isOnSearchAll ? null : (
                <Title>
                  {t('search_title_swagger', {
                    ns: TranslationNamespace.SEARCH,
                  })}
                </Title>
              )
            ) : (
              <Title>
                {t('user_online', {
                  ns: TranslationNamespace.SEARCH,
                })}
              </Title>
            )}
            <MoreButton
              data-element_id={ButtonId.Search.ButtonMoreCreator}
              to={{
                pathname: `/search/${searchType.SWAGGER}/${searchSwaggerCategoryPath.ONLINE}`,
                search,
              }}
              replace
            >
              {t('btn_show_more', {
                ns: TranslationNamespace.SEARCH,
              })}
              <img src={MoreIconSource} aria-hidden />
            </MoreButton>
          </Header>
        ) : null}
        {isOnSearchAll ? (
          <ScrollerWrapper>
            <ListWrapper>
              <ListPaddingWrapper>
                {list.map((id, index) => (
                  <ItemWrapper key={id}>
                    <UserFeedItem
                      id={id}
                      itemIndexInCategory={index}
                      categoryId={
                        hasQuery
                          ? CustomImpression.USER_SEARCH
                          : defaultCategory
                      }
                      withHalo
                      query={query}
                      shouldTrackEvent
                      fontSize={12}
                      marginTop={12}
                    />
                  </ItemWrapper>
                ))}
              </ListPaddingWrapper>
            </ListWrapper>
          </ScrollerWrapper>
        ) : (
          <InfiniteScroller
            pageStart={1}
            hasMore={nextPage !== null}
            loadMore={this.handleLoadMore}
            isFetching={isNextPageFetching}
            threshold={100}
          >
            <UserGridWrapper>
              <ScrollItem
                loader={
                  <LoadingWrapper>
                    <Loading />
                  </LoadingWrapper>
                }
              >
                {list.map((id, index) => (
                  <UserGridItem key={id}>
                    <UserFeedItem
                      id={id}
                      itemIndexInCategory={index}
                      categoryId={
                        hasQuery
                          ? CustomImpression.USER_SEARCH
                          : defaultCategory
                      }
                      withHalo
                      shouldTrackEvent
                      query={query}
                      shouldUseFollowButton
                      marginTop={12}
                    />
                  </UserGridItem>
                ))}
              </ScrollItem>
            </UserGridWrapper>
          </InfiniteScroller>
        )}
      </StyledSearchSwaggerList>
    );
  }
}

SearchSwaggerList.propTypes = {
  sortingString: PropTypes.string,
  query: PropTypes.string,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  defaultCategory: PropTypes.string,
  nextPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasQuery: PropTypes.bool,
  isOnSearchAll: PropTypes.bool,
  isNextPageFetching: PropTypes.bool,
  resultIds: PropTypes.array,
  defaultIds: PropTypes.array,
  fetchUserSearch: PropTypes.func,
  fetchFeeds: PropTypes.func,
  t: PropTypes.func.isRequired,
  subscribeFeedChannel: PropTypes.func,
  unsubscribeFeedChannel: PropTypes.func,
};

SearchSwaggerList.defaultProps = {
  sortingString: '',
  query: '',
  location: {},
  defaultCategory: null,
  nextPage: null,
  hasQuery: false,
  isOnSearchAll: false,
  isNextPageFetching: false,
  resultIds: [],
  defaultIds: [],
  fetchUserSearch: () => null,
  fetchFeeds: () => null,
  subscribeFeedChannel: () => null,
  unsubscribeFeedChannel: () => null,
};

const StyledSearchSwaggerList = styled.div.attrs(
  ({ isOnSearchAll, hasSwaggerFilter }) => ({
    style: {
      backgroundColor: isOnSearchAll ? color.black : 'unset',
      height: isOnSearchAll ? '214px' : 'unset',
      overflowY: isOnSearchAll ? 'hidden' : 'unset',
      marginTop: hasSwaggerFilter
        ? `${searchSwaggerFilterHeight + 8}px`
        : '8px',
    },
  })
)`
  margin-bottom: 20px;
  background-color: ${color.black};
`;

const Header = styled.div`
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  height: 16px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: ${color.white};
`;

const MoreButton = styled(Link)`
  border-radius: 4px;
  height: 28px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${textColor.white};
  font-size: 12px;
  line-height: 16px;
  border: solid 1px ${color.white};
  > img {
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }
`;

const ScrollerWrapper = styled.main`
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 0px;
`;

const ListWrapper = styled.div`
  display: inline-block;
`;

const ListPaddingWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: ${SCROLL_BAR_HEIGHT}px;
  display: flex;
`;

const ItemWrapper = styled.div`
  margin-right: 24px;
  padding-top: 6px;
  :last-child {
    margin-right: 0px;
  }
`;

const LoadingWrapper = styled.div`
  height: 133px;
`;

const UserGridWrapper = styled.div`
  padding: 0 16px;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(88px, 1fr));
`;

const UserGridItem = styled.div`
  margin-bottom: 16px;
`;

export default withRouter(withTranslation()(SearchSwaggerList));
